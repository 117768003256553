import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROLES } from 'src/app/core/constants';
import { ClientOneViewService } from 'src/app/services/client-oneview.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { UserService } from 'src/app/services/user.service';
import { IQuery } from 'src/app/shared/query';
declare var $: any;

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.css']
})
export class ClientViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isCreate = false;
  @Input() isEdit = false;
  @Input() viewId: any;
  @Input() canEdit = false;
  @Output() onSuccess = new EventEmitter<any>();

  userData: any;
  modelDetail: any;
  ClientNameList: any;
  isRm: boolean = false;
  isClientAdmin = false;
  isClient: boolean = false;
  isAdmin: boolean = false;
  isCCTAdmin: boolean = false;
  clientTypeSearch: any = 'duns';
  clientSuggestion: any = [];
  clientId: number = 0;
  clientText: any = '';
  selectedClients: any = [];
  viewName = '';
  isLoading = false;
  hasError = false;
  errorMsg = '';
  subs: Subscription[] = [];
  informationMsg: boolean = false;
  isFormChanged = false;

  constructor(private pageTemplateService: PageTemplateService,
    private oneViewService: ClientOneViewService,
    private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewId?.currentValue) {
      this.viewId = changes.viewId?.currentValue;
      if (!this.modelDetail || this.modelDetail.id !== this.viewId) {
        this.getViewDetail(this.viewId);
      }
    }
  }

  ngOnInit(): void {
    this.getClients();
    const user = this.userService.getUserListData();
    if (!user) {
      this.userService.callUserListData().subscribe((data) => {
        this.setUserData(data);
      });
    }
    else {
      this.setUserData(user);
    }
  }

  private setUserData(user: any): any {
    this.isAdmin = false;
    this.isCCTAdmin = false;
    this.userData = user;
    for (let p in user.roles) {
      const role = user.roles[p].roleTitle.toLowerCase();
      switch (role) {
        case ROLES.Admin.toLocaleLowerCase():
          this.isAdmin = true;
          break;
        case ROLES.CCT_Admin.toLocaleLowerCase():
          this.isCCTAdmin = true;
          break;
      }
    }
  }

  public isReadyToSubmit(): any {
    let isReady = this.selectedClients.length > 0 && !!this.viewName && !this.isLoading;
    if (this.isEdit) {
      isReady = this.isFormChanged && isReady;
    }

    return isReady;
  }

  private getViewDetail(id: any): any {
    this.isLoading = true;
    const sub = this.oneViewService.get(id)
      .pipe(
        map((detail: any) => {
          this.selectedClients = [];
          this.modelDetail = detail;
          this.modelDetail?.clients.forEach(item => {
            const client = this.clientSuggestion.find(el => el.id === item.clientId);
            if (client) {
              this.selectedClients.push(client);
            }
          });
          this.viewName = this.modelDetail?.oneViewGroupName
        })
      ).subscribe(_ => {
        this.isLoading = false;
      }, (error) => { 
        this.hasError = true;
        this.errorMsg = error;
        this.isLoading = false;
      });

    this.subs.push(sub);
  }

  public submit(): void {
    var customOneView = this.selectedClients.filter(x => x.isSrategicCLient == true);
    if (customOneView.length > 0) {
      $('#customConfirmModal').modal('show');
      return;
    }
    this.submitProgress();
  }

  public customSubmit(): void {
    $('#customConfirmModal').modal('hide');
    this.submitProgress();
  }

  private submitProgress() {
    this.isLoading = true;
    this.hasError = false;
    this.errorMsg = null;
    let viewClients = [];
    this.selectedClients.forEach(client => {
      viewClients.push({
        clientId: client.id,
        clientName: client.title,
        clientDuns: client.dunsId,
        isStrategicClient: client.isSrategicCLient,
        isActive: true
      });
    });

    const model = {
      oneViewGroupName: this.viewName,
      clients: viewClients,
      isActive: true
    };

    if (this.isCreate) {
      this.create(model);
    } else {
      this.update(model);
    }
  }

  private create(models: any): any {
    this.hasError = false;
    const sub = this.oneViewService.create(models).subscribe(res => {
      this.resetForm();
      this.onSuccess.emit('OneView has been created successfully!');
      this.isLoading = false;
    }, (error) => {
      this.hasError = true;
      this.isLoading = false;
      this.errorMsg = error;
      $('#clientViewBody').scrollTop(0);
    });
    this.subs.push(sub);
  }

  private update(models: any): any {
    this.hasError = false;
    const sub = this.oneViewService.update(this.modelDetail.id, models).subscribe(res => {
      this.isLoading = false;
      this.resetForm();
      this.onSuccess.emit('OneView has been updated successfully!');
    }, (error) => {
      this.hasError = true;
      this.isLoading = false;
      this.errorMsg = error;
      $('#clientViewBody').scrollTop(0);
    });
    this.subs.push(sub);
  }

  private delete(id: any): any {
    this.isLoading = true;
    this.hasError = false;
    const sub = this.oneViewService.delete(id).subscribe(res => {
      this.isLoading = false;
      this.resetForm();
      this.onCloseDeleteModal();
      this.onSuccess.emit('OneView has been deleted successfully!');
    }, (error) => {
      this.hasError = true;
      this.errorMsg = error;
      this.isLoading = false;
    });
    this.subs.push(sub);
  }

  onSelectClientChanged(currentSelectedClients: any): any {
    this.isFormChanged = true;
    if (this.isEdit) {
      this.isFormChanged = currentSelectedClients.length !== this.modelDetail.clients.length
        || currentSelectedClients.some(el => !this.modelDetail.clients.find(c => c.clientId === el.id));
    }

    const clientUserEmails = this.selectedClients.map(el => el.clientUserEmails);
    const numberOfClientUser = clientUserEmails.split(';');
    document.getElementById('clientList').style.display = numberOfClientUser.length < 15 ? 'unset' : 'block';
  }

  onOneViewNameChanged(event): any {
    if (!this.isEdit || !this.modelDetail) return;
    this.isFormChanged = event !== this.modelDetail.oneViewGroupName;
  }

  getClients() {
    this.isLoading = true;
    let suggestionSubscriber: any;
    const queryParam: IQuery = Object.assign({}, {});

    queryParam['searchClientBy'] = this.clientTypeSearch;
    if (this.isAdmin || this.isCCTAdmin) {
      suggestionSubscriber = this.pageTemplateService.getClientSuggestionsAdminOneView(queryParam);
    }
    else {
      suggestionSubscriber = this.pageTemplateService.getClientSuggestionsRmOneView(queryParam);
    }

    suggestionSubscriber.subscribe(
      data => {
        this.isLoading = false;
        this.clientSuggestion = data;
        if (this.isClientAdmin) {
          this.clientText = this.clientSuggestion.find(r => r.id === this.clientId).titleWithDuns;
        }
        if (!!this.viewId) {
          this.getViewDetail(this.viewId);
        }
      },
      error => { this.isLoading = false; }
    );
  }

  public handleClientUserEmail(emails: any): any {
    if (!emails) return null;

    const result = emails.replaceAll(';', '<br>');
    return result;
  }


  public onDeleteItem(): any {
    var data = this.selectedClients.map(el => el.title);
    this.ClientNameList = data.toString();
    
    $('#deleteConfirmModal').modal('show');
  }

  public onCloseDeleteModal(): any {
    $('#deleteConfirmModal').modal('hide');
  }

  public onCloseCustomOneViewModal(): any {
    $('#customConfirmModal').modal('hide');
  }

  public deleteCommitmentDetail(): void {
    this.delete(this.modelDetail?.id);
  }

  public closeOneViewModal(): any {
    this.resetForm();
  }
  private resetForm(): void {
    this.selectedClients = [];
    this.viewName = null;
    this.viewId = null;
    this.modelDetail = null;
    this.hasError = false;
    this.errorMsg = null;
    this.isFormChanged = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
