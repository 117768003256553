import { Component, OnDestroy, OnInit } from '@angular/core';
import { NoteReviewTrailDTO, OneViewNoteReviewTrailDTO } from '../client-review-trail-overall/models/note-review-trail-d-t-o';
import moment from 'moment';
import { ClientOneViewService } from 'src/app/services/client-oneview.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-oneview-review-trail',
  templateUrl: './oneview-review-trail.component.html',
  styleUrls: ['../client-review-trail-overall/client-review-trail-overall.component.css']
})
export class OneviewReviewTrailComponent implements OnInit, OnDestroy {
  oneViewId = 0;
  oneViewName: string;
  data = [];
  noteData = [];
  subs: Subscription[];
  isLoading = false;
  constructor(private oneViewService: ClientOneViewService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.oneViewId = params.id;
        this.loadNoteData(this.oneViewId);
      }
    });
   
  }

  private loadNoteData(oneViewId: number): void {
    if (oneViewId <= 0) return;

    this.isLoading = true;
    const sub = this.oneViewService.getOneViewNoteReviewTrail(this.oneViewId)
      .subscribe(res => {
        const result = res.body as OneViewNoteReviewTrailDTO;
        this.oneViewName = result.entityName;
        this.noteData = result.noteReviewTrails;
        this.noteData.forEach(item => {
          item.createdOn = moment.utc(item.createdOn).local();
        });
        this.isLoading = false;
      },
    (error) => {
      this.isLoading = false;

    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
